<template>
  <div class="mt-10 d-flex flex-column">
    <span class="font-label">{{ $t('username_or_email') }}</span>
    <input class="mt-1 input-normal" v-model="email" />
    <span class="mt-5 font-label">{{ $t('password') }}</span>
    <div class="mt-1 d-flex justify-content-between">
      <input class="w-100 input-normal" v-model="password" :type="showPassword ? 'text' : 'password'" />
      <v-btn icon @click="showPassword = !showPassword">
        <v-icon>{{ showPassword ? 'mdi-eye' : 'mdi-eye-off' }}</v-icon>
      </v-btn>
    </div>
    <v-btn class="ml-auto mt-2" text small @click="$router.push(`reset_password`)" v-if="!forLoginRegister">
      <span><u class="font-12">{{ $t('forgot_password') }}</u></span>
    </v-btn>
    <v-btn class="mt-5 button-normal" @click="normalLogin()">
      <span class="px-10">{{ $t('login') }}</span>
    </v-btn>
    <div class="mt-10 mb-5 center" v-if="forLoginRegister">
      <span class="font-20 font-bold">{{ $t('i_am_new_here') }}</span>
    </div>
    <div class="m-5 d-flex vertical-center" v-else>
      <div class="col-sm separator separator-solid"></div>
      <span class="mx-5 font-label">OR</span>
      <div class="col-sm separator separator-solid" />
    </div>
    <div class="d-flex justify-content-center vertical-center">
      <v-btn class="mx-5" icon @click="socialLoginTask(false)">
        <img :src="assets.facebook" :width="40" />
      </v-btn>
      <v-btn class="mx-5" icon @click="socialLoginTask(true)">
        <img :src="assets.google" :width="40" />
      </v-btn>
    </div>
  </div>
</template>

<style lang="scss">
  @import "@/assets/sass/pages/auth.scss";
</style>

<script>
import { FacebookAuthProvider, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import { firebaseAuth, functions } from '../../../main';
import { isValidEmail, showLoading, showFunctionError } from '../../../functions';

import TermsComponent from "@/view/pages/auth/TermsComponent.vue";

import google from '@/assets/images/google.png';
import facebook from '@/assets/images/facebook.png';

export default {
  name: 'LoginComponent',
  components: {
    TermsComponent
  },
  computed: {
    isMobile() {
      return this.$store.state.isMobile;
    }
  },
  props: {
    forLoginRegister: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      assets: {
        google,
        facebook
      },
      email: '',
      password: '',
      showPassword: false
    };
  },
  mounted() {
    localStorage.setItem('eventboxRegisterUser', '');
    if (this.$store.state.myId) {
      this.$router.push('/');
    }
  },
  methods: {
    normalLogin() {
      if (!this.email) {
        this.$toast.error(this.$t('please_enter') + this.$t('username_or_email'));
        return;
      }
      if (!this.password) {
        this.$toast.error(this.$t('please_enter') + this.$t('password'));
        return;
      }
      const md5 = require('md5');
      const params = {
        fieldPath: isValidEmail(this.email) ? 'email' : 'userName',
        value: this.email,
        password: md5(this.password)
      };
      this.loginTask(params);
    },
    socialLogin(user) {
      var email = null;
      if (user && user.email) {
        email = user.email;
      } else if (user && user.providerData && user.providerData.length > 0) {
        if (user.providerData[0].email) {
          email = user.providerData[0].email;
        } else {
          email = user.providerData[0].uid + this.SUFFIX_SOCIAL_WITHOUT_EMAIL;
        }
      }
      if (email) {
        const userInfo = {
          email: email,
          userName: user.displayName ? user.displayName.replace(/[^A-Z0-9]+/ig, '') : '',
          photoUrl: user.photoURL ? user.photoURL : '',
        };
        localStorage.setItem('eventboxRegisterUser', JSON.stringify(userInfo));
        const params = {
          fieldPath: 'email',
          value: email,
          password: ''
        };
        this.loginTask(params);
      } else {
        this.$toast.error(this.$t('alert_unexpected_error'));
      }
    },
    socialLoginTask(isGoogle) {
      const provider = isGoogle ? new GoogleAuthProvider() : new FacebookAuthProvider();
      provider.addScope('email');
      const loader = showLoading(this, 1);
      signInWithPopup(firebaseAuth, provider).then(result => {
        loader.hide();
        this.socialLogin(result.user);
      }).catch(error => {
        loader.hide();
        if (error.code === 'auth/popup-closed-by-user') {
          this.$toast.info(this.$t('alert_closed_by_user'));
        } else {
          this.$toast.error(`${error.code} - ${error.message}`);
        }
      });
    },
    loginTask(params) {
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'loginUser');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        if (response.data.startsWith(this.PREFIX_FROM_SYSTEM)) {
          this.$toast.error(response.data.substring(this.PREFIX_FROM_SYSTEM.length));
        } else {
          const myInfo = JSON.parse(response.data);
          if (myInfo && myInfo.userId) {
            if (myInfo.userType === this.USER_GUEST) {
              window.alert(this.$t('alert_login_to_app_first'));
            } else {
              this.$store.commit('set', ['myId', myInfo.userId]);
              this.$store.commit('set', ['myInfo', myInfo]);
              localStorage.setItem('eventboxMyId', myInfo.userId);
              localStorage.setItem('eventboxMyInfo', JSON.stringify(myInfo));
              this.updateUserTask(myInfo.userId);
            }
          } else if (response.data === this.RESULT_EMAIL_NOT_EXIST && !params.password) {
            this.$router.push(`select_user_type`);
          } else if (response.data === this.RESULT_USER_UNAPPROVED) {
            localStorage.setItem('eventboxRegisterUser', '');
            window.alert(this.$t('alert_unapproved_organizer'));
          } else {
            showFunctionError(this, response.data);
          }
        }
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    },
    updateUserTask(userId) {
      const params = {
        userId: userId,
        setStatus: true,
        deviceType: `Web : ${navigator.userAgent}`
      }
      const loader = showLoading(this, 1);
      const func = httpsCallable(functions, 'updateUser');
      func(JSON.stringify(params)).then(response => {
        loader.hide();
        this.$toast.success(this.$t('alert_success'));
        window.location.reload();
      }).catch(error => {
        loader.hide();
        this.$toast.error(error.code + ', ' + error.message);
      });
    }
  }
};
</script>